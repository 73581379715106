import React from "react";
import PropTypes from "prop-types";
import clientConfig from "../../client-config";
import BasePortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

const PortableText = ({ blocks, className }) => (
  <BasePortableText
    blocks={blocks}
    className={className}
    serializers={serializers}
    {...clientConfig.sanity}
    renderContainerOnSingleChild={true}
  />
);

PortableText.propTypes = {
  blocks: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default PortableText;
